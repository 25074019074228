import React, { cloneElement, useState, useRef } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { asignarLLamada, finalizarLLamada, enviarMotivo, revisarTurno } from '../services/api.js';
import { Tabs, Tab, Modal, Button } from 'react-materialize';
import Spinner from '../components/Spinner';
import { motivos } from '../services/constants'

var intervalFinalizar = null;
var intervalFinalizarCliente = null;

const VideoLlamada = () => {

  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [url, setUrl] = useState('');
  const [link, setLink] = useState('');
  const [uuidUser, setUuidUser] = useState('');
  const [nombreUser, setNombreUser] = useState(''); 
  const [telUser, setTelUser] = useState(''); 
  const [cancel, setCancel] = useState(''); 

  const [loading, setLoading] = useState(false);
  const [motivo, setMotivo] = useState('');
  const [descripcionMotivo, setDescripcionMotivo] = useState('');
  const [vista, setVista] = useState(false);


  useEffect(() => {

    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }

    let link_call = localStorage.getItem("link_call");
    if (link_call) {
      setLink(link_call);
    } else {
      M.toast({ html: 'No hay una conexión disponible' });
      //setTimeout(() => {
        history.push('/home');
      //}, 200);
    }
    let url = window.location.origin
    setUrl(url)
    //info()

    let clienteUuid = localStorage.getItem("clienteUuid");
    if (clienteUuid) {
      setUuidUser(clienteUuid);
    }

    let nombreCliente = localStorage.getItem("nombreCliente");
    if (nombreCliente) {
      setNombreUser(nombreCliente);
    }

    let telCliente = localStorage.getItem("telCliente");
    if (telCliente) {
      setTelUser(telCliente);
    }
    setLoading(true)

    revisarLlamada()

  }, [])

  useEffect(() => {
    //console.log("link:", link);
    if(link){
      cambiarStatus();
    }
  }, [link]);

  useEffect(() => {
    console.log("vista:", vista);
  }, [vista]);

  const cambiarStatus = async () => {
    asignarLLamada().then((response) => {
      if (response.status === 200) {
        //console.log(response.data);
      }
    }).catch((err) => {
      M.toast({ html: 'Revisa tu conexión de internet' });
      console.error(err);
      console.log("algun error" + err);
    })

  }

  const colgarLLamada = async () => {
    let motivoLlamada = `${motivo}: ${descripcionMotivo}`
    if (motivo && descripcionMotivo) {
      seleccionarMotivo(motivoLlamada);
    } else {
      M.toast({ html: 'Debes seleccionar un motivo para finalizar la llamada' });
    }
  }

  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    if(intervalFinalizar != null){
      clearInterval(intervalFinalizar)
    }
    if(intervalFinalizarCliente != null){
      clearInterval(intervalFinalizarCliente)
    }
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const stopCameras = () => {
    console.log("entre a cancelar");
    setVista(false)
    //document.querySelector('video').srcObject.getTracks()[0].stop()
  }

  const seleccionarMotivo = (motivo) => {
    setLoading(true);
    if(intervalFinalizar != null){
      clearInterval(intervalFinalizar)
    }
    if(intervalFinalizarCliente != null){
      clearInterval(intervalFinalizarCliente)
    }
    enviarMotivo(motivo).then(response => {
      if (response.status === 200) {
        finalizarLLamada().then((response) => {
          if (response.status === 200) {
            //console.log("llamada finalizada");
            localStorage.setItem("link_call", "");
            stopCameras()
            console.log("intervalFinalizarCliente",intervalFinalizarCliente);
            setTimeout(() => {
              history.push('/home');
            }, 2000);
          }
        }).catch((err) => {
          console.log("Error:" + err);
        })
      }
    }).catch(error => {
      console.error("Error:" + error);
      if (error.toString().includes("401")) {
        cerrar();
      }
    });
  }

  const revisarLlamada = async () => {
    console.log("entrando a revisarTurno");
    intervalFinalizar = setInterval(() => {
        revisarTurno().then(async (response) => {
            console.log("Esperando...", intervalFinalizar);
            if (response.status === 200) {
                if (response.data.payload.idStatusTurno === "EN_LLAMADA") {
                    console.log("EN_LLAMADA:", response.data.payload);
                    setLoading(false)
                    clearInterval(intervalFinalizar)
                    setTimeout(() => {
                      setVista(true)
                      revisarColgarCliente()
                    }, 1000);
                } else if (response.data.payload.idStatusTurno === "CANCELADO") {
                    setLoading(false)
                    clearInterval(intervalFinalizar);
                    mensaje()
                }else {
                    //console.log("sigue en llamada");
                }
            }
        }).catch((e) => {
            console.log("algun error turnofila" + e);
        })
    }, 500);
  }

  const revisarColgarCliente = async () => {
    console.log("entrando a revisarTurno");
    intervalFinalizarCliente = setInterval(() => {
        revisarTurno().then(async (response) => {
            //console.log("Esperando...", intervalFinalizarCliente);
            if (response.status === 200) {
                if (response.data.payload.idStatusTurno == "CANCELADO") { 
                    console.log("CANCELO CLIENTE:", response.data.payload.idStatusTurno);
                    setLoading(false)
                    clearInterval(intervalFinalizarCliente);
                    mensaje()
                } else {
                    //console.log("sigue en llamada");
                }
            }
        }).catch((e) => {
            console.log("algun error turnofila" + e);
        })
    }, 1000);
  }

  const mensaje = () => {
    if (intervalFinalizarCliente) {
      clearInterval(intervalFinalizarCliente);
      console.log("intervalFinalizarCliente",intervalFinalizarCliente);
      setTimeout(() => {
        setCancel("El cliente ha finalizado la llamada, por favor de terminar la llamada")
      }, 100);
    }

  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">
        <br />
        <div className="main_gradient2">

          <div className="contentIframe">
            {
              (localStorage.getItem("link_call") !== "" && vista) ?
                <iframe
                  id="iframe-videollamada"
                  autoPlay={true}
                  allowusermedia="true"
                  src={localStorage.getItem("link_call")+"?user=agente"}
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  marginHeight="0"
                  marginWidth="0"
                  className="iframeV"
                  allow="camera *; microphone *; autoplay;"
                />
                :
                <p>Espere un momento...</p>
            }

          </div>

          <div className="containerName">
            <div className="userName ">
              { 
                (nombreUser) ? nombreUser : "Usuario"
              }
              <br></br>
              { 
                (telUser) ? telUser : "Sin telefono"
              }
              <br></br>
              { 
                (cancel) ? cancel : ""
              }
            </div>
            </div>
          <div className="agent_actions">
            <div className="foot_call_view_b">
              <Button
                className="btn-floating btn-large waves-effect waves-light red agent-btn-margin"
                node="button"
                tooltip="Para finalizar debes seleccionar el motivo de la llamada"
                waves="light"
                onClick={e => colgarLLamada()}
              >
                <i className="material-icons">call_end</i>
              </Button>
              <div className="col s12"></div>
            </div>
          </div>

          <div className="agent_info grey lighten-2">


            <div className="col s12">
              <Tabs className="tabs">
                <Tab
                  options={{
                    duration: 300,
                    onShow: null,
                    responsiveThreshold: Infinity,
                    swipeable: false
                  }}
                  title="Motivo llamada"
                >
                  <div id="test4" className="col s12">
                    <div className="info_btns">

                      <form className="row">
                        <div className='col 12 width_50'>
                          <select className="new-custom-select" id="motivo" name="motivo" onChange={e => setMotivo(e.target.value)} value={motivo} >
                            <option value="" disabled id="uno">Seleccionar:</option>
                            {
                              motivos.map(
                                (objeto, index) => (
                                  <option key={`${index}`} value={`${objeto.descripcion}`}>{objeto.descripcion}</option>
                                )
                              )
                            }
                          </select>
                        </div>
                        <div className='col 12 width_50'>
                          {motivo &&
                            <input type="text" className="form-control" id="empresa" name="empresa" defaultValue={descripcionMotivo} onChange={e => setDescripcionMotivo(e.target.value)} />}
                        </div>
                      </form>


                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>

          </div>
        </div>
      </div>

      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>

    </div>


  )
}



export default VideoLlamada