import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import M from 'materialize-css';
import { useHistory } from "react-router-dom";
import HeaderPage from "../components/HeaderPage";
import LogoutPopup from "../components/LogoutPopup";
import { obtenerListaTurnosPorAgente } from '../services/api.js';
import { Table, Badge, Modal } from "react-materialize";
import { TableHeader, Pagination, Search } from "../components/DataTable";
import Spinner from '../components/Spinner';

const HomeEnProceso = () => {


  const history = useHistory();
  const [tiempo, setTiempo] = useState('');
  const [hostMinio, setHostMinio] = useState('proddicio');
  const [dataClientes, setDataClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [rol, setRol] = useState("");
  const [dataOtorgante, setDataOtorgante] = useState([]);
  const [currentClientId, setCurrentClientId] = useState('');
  const [currentTransId, setCurrentTransId] = useState('');
  const [currentOtorganteId, setCurrentOtorganteId] = useState('');
  const [nombreCliente, setNombreCliente] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchOtorgante, setSearchOtorgante] = useState("");

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 12;
  const headers = [
    //{ name: "ID", field: "clienteUuid", sortable: false },
    { name: "Contacto", field: "nombreCompleto", sortable: false },
    { name: "Teléfono", field: "telefono", sortable: false },
    { name: "Duración", field: "duracion", sortable: false },
    //{ name: "Estatus (Llamada)", field: "status", sortable: false },
    { name: "Fecha / hora inicio", field: "fechaInicio", sortable: false },
    { name: "Fecha / hora fin", field: "fechaFin", sortable: false },
    { name: "Motivo", field: "motivo", sortable: false },
  ];
  const [totalLlamadas, setTotalLlamadas] = useState(0);

  const dataListaClientes = useMemo(() => {
    let computedComments = dataClientes;
    setTotalItems(computedComments.length);
    setTotalItems(computedComments.length);
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) =>
          reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [dataClientes, currentPage, sorting]);

  useEffect(() => {
    let user = localStorage.getItem("username")
    if (user === "") {
      history.push('/index');
    }
    let to = localStorage.getItem("token")
    if (to === "") {
      history.push('/index');
    }
    let time = localStorage.getItem("tiempo");
    if (time !== null) {
      setTiempo(time)
    }
    let host = window.location.hostname;
    if (host.includes("adminotorgante.devdicio")) {
      setHostMinio("devdicio");
    } else if (host.includes("adminotorgante.dev.devdicio")) {
      setHostMinio("dev.devdicio");
    }

    let lista = localStorage.getItem("dataOtorgante");
    if (lista !== null) {
      let options = []
      let final = JSON.parse(lista)
      for (var i = 0; i < final.length; i++) {
        var obj = final[i];
        var nuevo = new Object();
        nuevo.id = obj.id;
        nuevo.nombre = obj.nombre;
        options.push(nuevo)
      }
      setDataOtorgante(options)
    }

    let rolUser = localStorage.getItem("roles");
    if (rolUser !== null) {
      setRol(rolUser)
    }

    obtenerListaClientes();
  }, [])

  useEffect(() => {
    localStorage.setItem("listaClientes", JSON.stringify(dataClientes));
    setDataClientes(dataClientes);
  }, [dataClientes])

  const obtenerListaClientes = async () => {
    //console.log("obtener lista");
    setLoading(true);
    let statusTurno = 'TERMINADO';
    obtenerListaTurnosPorAgente(statusTurno).then((res) => {
      if (res.status === 200) {
        setLoading(false)
        let data = res.data.payload.sort(function (a, b) { return b.id - a.id });
        setTotalLlamadas(data.length);
        setDataClientes(data);
      }
    }).catch((err) => {
      setLoading(false);
      console.log("algun error:", err);
      if (err.toString().includes("401")) {
        cerrar();
      }
    }).finally(() => {
      setLoading(false);
    });
  }

  const cerrar = () => {
    M.toast({ html: 'Tiempo de sesión terminado' });
    setTimeout(() => {
      history.push('/index');
    }, 200);
  }

  const actualizar = () => {
    obtenerListaClientes();
  }

  const calcularDuracion = (fechaInicio, fechaFin) => {
    let duracion = (Math.round((new Date(fechaFin) - new Date(fechaInicio)) / 60000)).toString() + " min.";
    return duracion;
  }

  const convertirFecha = (fecha) => {
    let fechaDate = new Date(fecha);
    let options = { weekday: "long", year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: '2-digit', second: '2-digit', hour12: "true" };

    return fechaDate.toLocaleString('es-US', options);
  }

  return (
    <div>
      <HeaderPage></HeaderPage>
      <div className="row">

        <br /><br />
        <div className="col s12 m11 z-depth-3 mzauto extra_pdd">

          <div className="col s12 jumbotron clear_float">
            <h4>En proceso <small></small></h4>
            <p className="lead">Ve el listado de procesos terminados.</p>
            <hr className="my-4" />
            <a href="" className="waves-effect waves-light btn gray go_call_btn" onClick={() => actualizar()}>actualizar</a>
          </div>
          <div className="row">
              <div className=" input-field col s12">
                <Badge
                  className="gray accent-4 ajustarBadge"
                  newIcon>
                  <b>Total llamadas: {totalLlamadas}</b>
                </Badge>
              </div>
          </div>
          <br></br>
          <Table>
            <TableHeader
              headers={headers}
              onSorting={(field, order) =>
                setSorting({ field, order })
              }
            />
            <tbody>
              {(dataListaClientes.length > 0) ? dataListaClientes.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.nombreCompleto ? item.nombreCompleto : "Sin nombre"}</td>
                  <td><a href={"tel:" + item.telefono ? item.telefono : ""}>{item.telefono ? item.telefono : "Sin teléfono"}</a></td>
                  <td>{item.fechaFinTurno ? calcularDuracion(item.fechaAsignacionTurno, item.fechaFinTurno) : "Sin duración"}</td>
                  <td>{convertirFecha(item.fechaAsignacionTurno)}</td>
                  <td>{convertirFecha(item.fechaFinTurno)}</td>
                  <td>{(item.motivo) ? item.motivo : 'Sin motivo'}</td>
                </tr>
              )) :
                <tr>
                  <td colSpan="6">
                    Sin resultados.
                  </td>
                </tr>}
            </tbody>
          </Table>
          <br></br>

          <div className="row">
            <div className="col s12 ">
              <div className="centrarDiv">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={page => setCurrentPage(page)}
                />
              </div>
            </div>

          </div>

        </div>

      </div>

      <Modal
        actions={[
          <span>
            <a href="#!" className="modal-close waves-effect btn">Cerrar</a>
          </span>
        ]}
        bottomSheet={false}
        fixedFooter={true}
        header={"Cliente: " + nombreCliente}
        id="modalDetails"
        open={isModalOpen}
        root={document.body}
      >
        <div className="row">

          <table className="responsive-table">
            <thead>
              <tr>
                <th>Tipo UID:</th>
                <th>UID:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Cliente
                </td>
                <td>
                  <b>
                    {currentClientId}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Transacción
                </td>
                <td>
                  <b>
                    {currentTransId}
                  </b>
                </td>
              </tr>
              <tr>
                <td>
                  Otorgante
                </td>
                <td>
                  <b>
                    {currentOtorganteId}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>


      <Spinner state={loading}></Spinner>

      <LogoutPopup></LogoutPopup>
    </div>
  )
}

export default HomeEnProceso