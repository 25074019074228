import axios from 'axios';

const host = "https://api.proddicio.net:8444"
const servicio = "prod_"

//const host = process.env.REACT_APP_URL || "https://api.proddicio.net:8444"
//const servicio = process.env.REACT_APP_SERVICE || "prod_"

const endpoints = {
    otorgante: `${host}/v1/sec_${servicio}otorgante_general/api/otorgante/`,
    otorganteParams: `${host}/v1/sec_${servicio}otorgante_general/api/otorganteParams/`,
    usuarios: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    score: `${host}/v1/sec_${servicio}query_scores`,
    login: `${host}/v1/sec_${servicio}otorgante_general_login_public`,
    refresh: `${host}/v1/sec_${servicio}otorgante_general_refresh_public`,
    infoUser: `${host}/v1/sec_${servicio}otorgante_general_usuario_info`,
    cambiarPass: `${host}/v1/sec_${servicio}otorgante_general/api/usuario/`,
    reset: `${host}/v1/sec_${servicio}otorgante_general_reset_password/`,
    getAgenteInfo: `${host}/v1/sec_${servicio}otorgante_general_agente_info/`,
    getTurnoAgente: `${host}/v1/sec_${servicio}otorgante_general/api/turnoAgente/`,
    getTurnos: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    asignarAgenteATurno: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    finalizarAgenteATurno: `${host}/v1/sec_${servicio}otorgante_general/api/turno/`,
    compararRostro: `${host}/v1/sec_${servicio}identification_face_comparison`,
    turnoFila: `${host}/v1/sec_${servicio}otorgante_general_get_turn`,

};

/*Sesion*/
export function initSesion(username, password) {
    return axios({
        method: 'post',
        url: endpoints.login,
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            username: username,
            password: password
        }
    })
}

export function getInfo(username) {
    return axios({
        method: 'get',
        url: endpoints.infoUser,
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            usuario: username
        }
    })
}

export function refreshSesion(r_token) {
    return axios({
        method: 'post',
        url: endpoints.refresh,
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            refresh_token: r_token,
            grant_type: "refresh_token"
        }
    })
}

/** Turnos video llamada */

export function obtenerInfoAgente() {
    return axios({
        method: 'get',
        url: endpoints.getAgenteInfo,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    })
}

export function tomarLLamada() {
    return axios({
        method: 'get',
        url: endpoints.getTurnoAgente,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
    })
}

export function obtenerListaTurnos(statusTurno) {
    return axios({
        method: 'get',
        url: endpoints.getTurnos,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            idStatusTurno: statusTurno
        },
    })
}

export function asignarLLamada() {
    return axios({
        method: 'put',
        url: endpoints.asignarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idAgente": localStorage.getItem("id_agente"),
            "idStatusTurno": "ASIGNADO"
        }
    })
}

export function revisarTurno() {
    return axios({
        method: 'get',
        url: endpoints.turnoFila,
        headers: {
            'Content-Type': 'application/json',
            'clienteTransaccion': localStorage.getItem("clienteTrx"),
            'clienteUuid': localStorage.getItem("clienteUuid"),
            'apikey': localStorage.getItem("apikey")
        }
    })
}

export function finalizarLLamada() {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "idStatusTurno": "TERMINADO"
        }
    })
}

export function enviarMotivo(motivo) {
    return axios({
        method: 'put',
        url: endpoints.finalizarAgenteATurno + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            "motivo": motivo
        }
    })
}

export function obtenerValidaciones() {
    return axios({
        method: 'get',
        url: endpoints.getTurnos + localStorage.getItem("id_call"),
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}

/*USER*/

export function actualizarContrasena(iduser, pass) {

    return axios({
        method: 'PUT',
        url: endpoints.cambiarPass + iduser,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: {
            password: pass,
            passwordChanged: true
        }
    })
}

export function resetUser(email_reset) {

    return axios({
        method: 'post',
        url: endpoints.reset,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            email: email_reset
        }
    })
}
/* Otorgante */

export function obtenerOtorgantes() {
    let json = JSON.stringify({ "idOtorgante": localStorage.getItem("apikey") })
    return axios({
        method: 'get',
        url: endpoints.otorgante,
        headers: {
            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token")
        },
        data: json
    })
}
/* COMPARAR ROSTRO */

export function compararRostro(uuidUser, base64Image) {

    return axios({
        method: 'post',
        url: endpoints.compararRostro,
        headers: {

            'Content-Type': 'application/json',
            'apikey': localStorage.getItem("apikey"),
            "token": localStorage.getItem("token")
        },
        data: {
            "uuidUser": uuidUser,
            "id_turno": localStorage.getItem("id_call"),
            "comparetoImage": base64Image,
            "bucket": "dco-bucket-" + servicio.replace("_", "")
        }
    })
}

export function obtenerListaTurnosPorAgente(statusTurno) {
    return axios({
        method: 'get',
        url: endpoints.getTurnos,
        headers: {
            'apikey': localStorage.getItem("apikey"),
            "Authorization": "Bearer " + localStorage.getItem("token"),
            'idAgente': localStorage.getItem("id_agente")
        },
    })
}